import React from "react"
import styled from "styled-components"
import Parser from "html-react-parser"

import globalVariables from "../globalVariables"

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  @media (min-width: ${globalVariables.minTablet}) {
    flex-direction: row;
  }
`

const Left = styled.div`
  background-color: ${props => props.theme.secondary};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
  padding: 50px 20px;
  padding-left: 20px;
  @media (min-width: ${globalVariables.minTablet}) {
    flex: 0 0 50%;
    padding: 50px;
  }
`

const Title1 = styled.p`
  font-size: 38px;
  font-weight: bold;
  color: ${props => props.theme.primary};
  margin-bottom: 10px;
`

const Title2 = styled.h2`
  font-size: 40px;
  font-weight: bold;
  color: ${props => props.theme.white};
  margin-bottom: 0;
  text-align: right;
`

const Right = styled.div`
  display: flex;
  align-items: center;
  padding: 40px 20px 20px 35px;
  @media (min-width: ${globalVariables.minTablet}) {
    flex: 0 0 50%;
    padding: 80px 20px 60px 70px;
  }
`

const Text = styled.div`
  li {
    margin-bottom: 20px;
    line-height: 1.5;
  }
`

const Content = ({ title, text }) => {
  return (
    <Wrapper>
      <Left>
        <Title1>SLAP digital :</Title1>
        <Title2>{title}</Title2>
      </Left>
      <Right>
        <Text>{Parser(text)}</Text>
      </Right>
    </Wrapper>
  )
}

Content.propTypes = {}

export default Content
