import React from "react"
import styled from "styled-components"
import Img from "gatsby-image"
import LightSpeed from "react-reveal/LightSpeed"

import globalVariables from "../globalVariables"
import Section from "../elements/Section"
import { TitleH2 } from "../elements/Title"

const Container = styled.div`
  display: flex;
  flex-direction: column;
  @media (min-width: ${globalVariables.minTablet}) {
    flex-direction: row;
  }
`

const WrapperImage = styled.div`
  display: none;
  position: relative;
  @media (min-width: ${globalVariables.minTablet}) {
    display: block;
    flex: 1 0 50%;
  }
`

const Intro = styled.div`
  background-color: ${props => props.theme.primary};
  padding: 50px 20px;
  border-top: 1px solid white;

  @media (min-width: ${globalVariables.minTablet}) {
    border-top: none;
    flex: 1 0 50%;
    padding: 50px 65px;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  @media (min-width: ${globalVariables.minDesktop}) {
    padding: 80px 65px;
  }
`

const Text = styled.p`
  color: white;
  margin-top: 60px;
  margin-bottom: 0;
  @media (min-width: ${globalVariables.minTablet}) {
    margin-top: 25px;
  }
`

const Step = styled.div`
  min-height: 180px;
  padding: 19px 30px 30px 30px;

  position: relative;
  &:nth-child(odd) {
    background-color: ${props => props.theme.greyDarker};
  }
  &:nth-child(even) {
    background-color: #424750;
  }
  @media (min-width: ${globalVariables.minTablet}) {
    flex: 0 0 25%;
    min-height: 285px;
    padding: 55px 15px 55px 40px;
    &:not(:last-child)::before {
      content: "";
      position: absolute;
      top: 50%;
      right: -10px;
      transform: translateY(-50%);
      width: 60px;
      height: 3px;
      background-color: rgba(255, 255, 255, 0.25);
    }
    &:not(:last-child)::after {
      content: "";
      position: absolute;
      top: 50%;
      right: -10px;
      transform: translateY(-50%);
      width: 20px;
      height: 20px;
      background-color: ${props => props.theme.primary};
      border-radius: 50%;
      z-index: 1;
    }
  }
  @media (min-width: ${globalVariables.minDesktop}) {
    &:not(:last-child)::before {
      width: 100px;
    }
  }
  @media (min-width: ${globalVariables.medDesktop}) {
    &:not(:last-child)::before {
      width: 170px;
    }
  }
`

const Number = styled.p`
  font-size: 80px;
  font-weight: bold;
  opacity: 0.25;
  color: white;
  margin: 0;
  @media (min-width: ${globalVariables.minTablet}) {
    font-size: 90px;
  }
  @media (min-width: ${globalVariables.minDesktop}) {
    font-size: 120px;
  }
`

const StepText = styled.p`
  font-size: 18px;
  font-weight: bold;
  color: white;
  text-transform: uppercase;
  margin: 0;
  @media (min-width: ${globalVariables.minTablet}) {
    font-size: 16px;
    max-width: 100px;
  }
  @media (min-width: ${globalVariables.minDesktop}) {
    font-size: 18px;
    max-width: 285px;
  }
`

const Methodologie = ({ title, text, image, items }) => {
  return (
    <Section padding="0" border id="methodologie">
      <Container>
        <WrapperImage>
          <Img fluid={image.localFile.childImageSharp.fluid} />
        </WrapperImage>
        <Intro>
          <TitleH2 color="white" className="title1 trait" traitLeft="-65px">
            {title}
          </TitleH2>
          <Text>{text}</Text>
        </Intro>
      </Container>
      <Container>
        {items.map((item, index) => (
          <Step key={index}>
            <LightSpeed right delay={index * 200}>
              <Number>0{index + 1}</Number>
              <StepText>{item.titre_etape}</StepText>
            </LightSpeed>
          </Step>
        ))}
      </Container>
    </Section>
  )
}

export default Methodologie
