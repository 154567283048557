import React from "react"
import { graphql } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Methodologie from "../components/Metiers/methodologie"
import Prestations from "../components/Metiers"
import Clients from "../components/Home/Clients"
import Certifications from "../components/Home/Certifications"
import Temoignage from "../components/Home/Temoignage"
import Outils from "../components/Metiers/outils"
import Content from "../components/Metiers/content"
import Faq from "../components/elements/Faq"
import Blog from "../components/Metiers/blog"
import Contact from "../components/Home/Contact"
import { getDateFormat } from "../utils/getDateFormat"
import SeoManager from "../components/Metiers/seoManager"
import BannerV2 from "../components/elements/BannersV2/BannerV2"

const anchors = [
  // {
  //   content: "Besoins",
  //   anchor: "agence-bloc-1",
  // },
  {
    content: "Nos prestations",
    anchor: "prestations",
  },
  {
    content: "Nos accompagnements",
    anchor: "methodologie",
  },
]

const Metier = ({ data, location }) => {
  const currentMetier = data.metiers.acf.metier.toLowerCase()
  const {
    metiers,
    bloc_references,
    bloc_temoignages,
    bloc_certifications,
    bloc_seo_manager,
    articles,
    bloc_contact,
  } = data

  const bannerLeftCardContent = {
    mainContent: metiers.acf.texte,
    titleFirstLine: metiers.acf.titre_1,
    titleSecondLine: metiers.acf.titre_2,
    subTitle: metiers.acf.sous_titre,
  }

  return (
    <Layout
      location={`/${location.pathname.split("/")[1]}`}
      locationBis={location.pathname}
      crumbLabel="Nos Expertises"
      crumbLabelBis={metiers.acf.metier}
      locationGTM={location.href}
      noLocation
    >
      <SEO
        title={metiers.acf.title}
        description={metiers.acf.meta_description}
        lang="fr"
        url={location.pathname}
      />
      <BannerV2
        anchors={anchors}
        hasBreadCrumb
        hasTypeform
        hasSlider
        isMetierPage
        bannerLeftCardContent={bannerLeftCardContent}
        articles={articles.edges
          .filter(item => metiers.acf.metier === item.node.acf.theme_article)
          .sort(
            (a, b) =>
              getDateFormat(b.node.acf.date_de_publication) -
              getDateFormat(a.node.acf.date_de_publication)
          )
          .slice(0, 3)}
      />
      <Methodologie
        title={metiers.acf.titre_methodologie}
        text={metiers.acf.texte_methodologie}
        image={metiers.acf.image_methodologie}
        items={metiers.acf.etapes_methodologie}
      />
      {currentMetier === "seo" && (
        <SeoManager
          title={bloc_seo_manager.acf.titre_seo_manager}
          text1={bloc_seo_manager.acf.texte_seo_manager_1}
          text2={bloc_seo_manager.acf.text_seo_manager_2}
          images={bloc_seo_manager.acf.images_seo_manager}
        />
      )}
      <Prestations
        title={metiers.acf.titre_prestations}
        text={metiers.acf.texte_prestations}
        items={metiers.acf.liste_des_prestations}
      />
      <Outils
        title={metiers.acf.titre_outils}
        text={metiers.acf.texte_outils}
        items={metiers.acf.outils}
      />
      <Faq
        title={metiers.acf.titre_faq}
        text={metiers.acf.texte_faq}
        themes={metiers.acf.themes_faq}
      />
      <Content title={metiers.acf.titre_libre} text={metiers.acf.texte_libre} />
      <Clients
        title={bloc_references.acf.titre_references}
        text={bloc_references.acf.texte_reference}
        items={bloc_references.acf.reference}
      />
      <Certifications
        title={bloc_certifications.acf.titre_certification}
        text={bloc_certifications.acf.texte_certification}
        items={bloc_certifications.acf.certifications}
      />

      <Temoignage
        title={bloc_temoignages.acf.titre_temoignages}
        items={bloc_temoignages.acf.temoignages}
      />

      <Blog
        items={articles.edges
          .filter(item => metiers.acf.metier === item.node.acf.theme_article)
          .sort(
            (a, b) =>
              getDateFormat(b.node.acf.date_de_publication) -
              getDateFormat(a.node.acf.date_de_publication)
          )
          .slice(0, 4)}
      />
      <Contact
        title={bloc_contact.acf.titre_contact}
        text={bloc_contact.acf.texte_contact}
        location={location.href}
      />
    </Layout>
  )
}

export default Metier

export const query = graphql`
  query($id: String!, $theme: String!) {
    metiers: wordpressWpMetiers(id: { eq: $id }) {
      acf {
        title
        meta_description
        hashtag
        titre_1
        titre_2
        sous_titre
        texte
        metier
        titre_methodologie
        texte_methodologie
        image_methodologie {
          localFile {
            childImageSharp {
              fluid(quality: 100) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
        etapes_methodologie {
          titre_etape
        }
        titre_prestations
        texte_prestations
        liste_des_prestations {
          titre_prestation
          texte_prestation
          image_prestation {
            localFile {
              childImageSharp {
                fluid(srcSetBreakpoints: 400, maxWidth: 400) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
          url_prestation
        }
        titre_outils
        texte_outils
        outils {
          categorie
          logo {
            localFile {
              childImageSharp {
                fluid(quality: 100) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
        }
        titre_libre
        texte_libre
        titre_faq
        texte_faq
        themes_faq {
          titre_theme_faq
          questions_faq {
            question_faq
            reponse_faq
            lien_faq
            texte_bouton_faq
          }
        }
      }
    }
    bloc_references: wordpressAcfBlocs(
      id: { eq: "c995352c-c671-54ba-872f-0404f5c8a456" }
    ) {
      acf {
        titre_references
        texte_reference
        reference {
          couleur_reference
          lien_reference
          image_reference {
            localFile {
              childImageSharp {
                fluid(quality: 100) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
          logo_reference {
            localFile {
              childImageSharp {
                fluid(quality: 100) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
        }
      }
    }
    bloc_certifications: wordpressAcfBlocs(
      id: { eq: "b093d1ee-95cf-54db-b63c-5f4803c934fb" }
    ) {
      acf {
        titre_certification
        texte_certification
        certifications {
          logo_certification {
            alt_text
            localFile {
              childImageSharp {
                fluid(quality: 100) {
                  src
                }
              }
            }
          }
        }
      }
    }
    bloc_temoignages: wordpressAcfBlocs(
      id: { eq: "bb45179a-ff3e-53bb-8add-80a2dec1388b" }
    ) {
      acf {
        titre_temoignages
        temoignages {
          nom_temoignage
          texte_temoignage
          fonction_temoignage
          logo_temoignage {
            localFile {
              childImageSharp {
                fluid(quality: 100) {
                  src
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
        }
      }
    }
    bloc_seo_manager: wordpressAcfBlocs(
      id: { eq: "bcfb9d55-19c5-5043-8568-8a5377f3bb4c" }
    ) {
      acf {
        titre_seo_manager
        texte_seo_manager_1
        text_seo_manager_2
        images_seo_manager {
          texte_image
          image {
            localFile {
              childImageSharp {
                fluid(quality: 100) {
                  src
                }
              }
            }
          }
        }
      }
    }
    bloc_valeurs: wordpressAcfBlocs(
      id: { eq: "260faed8-6243-5382-90f5-6012aaa140c8" }
    ) {
      acf {
        valeurs {
          valeur {
            texte_valeur
            titre_valeur
            image_valeur {
              localFile {
                childImageSharp {
                  fluid(quality: 100) {
                    src
                  }
                }
              }
            }
          }
        }
        titre_valeurs
      }
    }
    articles: allWordpressPost(
      limit: 4
      filter: { acf: { theme_article: { eq: $theme } } }
      sort: { order: DESC, fields: modified }
    ) {
      edges {
        node {
          path
          acf {
            date_de_publication
            type_article
            theme_article
            titre_article
            intro_article
            vignette_article {
              localFile {
                childImageSharp {
                  fluid(srcSetBreakpoints: 400, maxWidth: 400) {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                }
              }
            }
          }
        }
      }
    }
    bloc_contact: wordpressAcfBlocs(
      id: { eq: "76393d63-8408-5238-b201-7f73d6fe7a78" }
    ) {
      acf {
        titre_contact
        texte_contact
      }
    }
  }
`
