import React from "react"
import styled from "styled-components"
import Flip from "react-reveal/Flip"

import Slider from "react-slick"
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"

import Section from "../elements/Section"
import Container from "../elements/Container"
import { TitleH2 } from "../elements/Title"
import Button from "../elements/Button"
import globalVariables from "../globalVariables"
import { NextArrow, PrevArrow } from "../elements/ArrowCarousel"

const WrapperSlider = styled.div`
  @media (max-width: ${globalVariables.maxMobile}) {
    .slick-next {
      right: -15px;
    }
    .slick-prev {
      left: -15px;
    }
  }
`

const Item = styled.div`
  text-align: center;
  margin-bottom: 20px;
  position: relative;
  margin: 20px 20px 30px;
`

const Text = styled.p`
  padding: 5px 0 20px;
  max-width: 700px;
  margin: auto;
  text-align: center;
  @media (min-width: ${globalVariables.minTablet}) {
    padding: 0 0 40px;
  }
`

const ImageTexte = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  color: white;
  width: 100%;
  height: 100%;
  background-color: black;
  opacity: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: opacity 0.2s ease-in-out;
  &:hover {
    opacity: 0.8;
  }
`

const WrapperButton = styled.div`
  text-align: center;
`

const SeoManager = ({ title, text1, text2, images }) => {
  const settings = {
    dots: false,
    infinite: true,
    speed: 1000,
    slidesToShow: 3,
    slidesToScroll: 1,
    initialSlide: 0,
    autoplay: true,
    nextArrow: <NextArrow color />,
    prevArrow: <PrevArrow color />,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  }

  return (
    <Section color="white">
      <Container>
        <TitleH2 className="title1" position="center">
          {title}
        </TitleH2>
        <Text>{text1}</Text>
        <WrapperSlider>
          <Slider {...settings}>
            {images.map((item, index) => (
              <Flip key={index} left delay={index * 300}>
                <Item>
                  <img
                    src={item.image.localFile.childImageSharp.fluid.src}
                    alt="seo manager"
                  />
                  <ImageTexte>{item.texte_image}</ImageTexte>
                </Item>
              </Flip>
            ))}
          </Slider>
        </WrapperSlider>
        <Text>{text2}</Text>
        <WrapperButton>
          <Button
            as="link"
            title="Demander une demo"
            to="/contact"
            colors="borderPrimaryHoverPrimary"
          />
        </WrapperButton>
      </Container>
    </Section>
  )
}

SeoManager.propTypes = {}

export default SeoManager
