import React from "react"
import styled from "styled-components"
import Img from "gatsby-image"
import { Link } from "gatsby"

import Slide from "react-reveal/Slide"

import ButtonPlus from "../elements/Button/ButtonPlus"
import Container from "../elements/Container"
import { TitleH2, TitleH3 } from "../elements/Title"
import globalVariables from "../globalVariables"

const Section = styled.section`
  position: relative;
  padding: 45px 0;
  background-color: ${props => props.theme.greyLighter};
  z-index: 0;
  @media (min-width: ${globalVariables.minTablet}) {
    padding: 65px 0;
    overflow: hidden;
  }
`

const Text = styled.p`
  padding: 5px 0 20px;
  max-width: 660px;
  margin: auto;
  text-align: center;
  @media (min-width: ${globalVariables.minTablet}) {
    padding: 0 0 40px;
  }
`

const WrapperPrestations = styled.div`
  display: flex;
  flex-direction: column;
  @media (min-width: ${globalVariables.minTablet}) {
    flex-direction: row;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    & > div {
      flex: 0 0 50%;
    }
  }
  @media (min-width: ${globalVariables.minDesktop}) {
    & > div {
      flex: 0 0 33%;
    }
  }
  @media (min-width: ${globalVariables.medDesktop}) {
    & > div {
      flex: 0 0 25%;
    }
  }
`
const CardText = styled.div`
  @media (min-width: ${globalVariables.minTablet}) {
    position: relative;
    text-align: center;
    color: ${props => props.theme.white};
    /* min-height: 57px; */
    p {
      margin-bottom: 0;
    }
  }
`

const WrapperCard = styled.div`
  padding: 12.5px;
`

const Card = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0 14px;
  text-decoration: none;
  min-height: 150px;
  width: 100%;
  .title2 {
    font-size: 19px;
    text-align: center;
    margin: 0;
    color: ${props => props.theme.white};
  }
  ${CardText} {
    opacity: 0;
    font-size: 0;
    transition: font-size 0.1s ease-in-out, opacity 0.3s ease-in-out;
  }
  @media (min-width: ${globalVariables.minTablet}) {
    padding: 0 18px;
    min-height: 265px;
    cursor: pointer;
    &:hover {
      ${CardText} {
        opacity: 1;
        font-size: 16px;
      }
    }
    .title2 {
      font-size: 22px;
      margin-bottom: 5px;
    }
  }

  .gatsby-image-wrapper {
    width: 100%;
    height: 100%;
    position: absolute !important;
    top: 0;
    left: 0;
  }
`

const Background = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: ${props => props.theme.black};
  opacity: 0.7;
`

const WrapperPlus = styled.div`
  text-align: center;

  @media (max-width: ${globalVariables.maxMobile}) {
    & > button {
      width: 40px;
      height: 40px;
      min-width: auto;
    }
  }
  @media (min-width: ${globalVariables.minTablet}) {
    margin-top: 22px;
  }
`

const CardContent = ({ item }) => (
  <Card>
    <Img fluid={item.image_prestation.localFile.childImageSharp.fluid} />
    <Background />
    <div>
      <TitleH3 className="title2" position="center">
        {item.titre_prestation}
      </TitleH3>
      <CardText>{item.texte_prestation}</CardText>
    </div>
    {item.url_prestation && (
      <WrapperPlus>
        <ButtonPlus />
      </WrapperPlus>
    )}
  </Card>
)

const Prestations = ({ title, text, items }) => {
  const getPathname = url =>
    url.includes("/expertise")
      ? new URL(url).pathname
      : "/expertise" + new URL(url).pathname
  return (
    <Section id="prestations">
      <Container>
        <TitleH2 className="title1" position="center">
          {title}
        </TitleH2>
        <Text>{text}</Text>
        <WrapperPrestations>
          {items.map((item, index) => (
            <Slide bottom key={index}>
              <WrapperCard>
                {item.url_prestation && item.url_prestation.length > 0 ? (
                  <Link
                    to={getPathname(item.url_prestation)}
                    style={{ textDecoration: "none" }}
                  >
                    <CardContent item={item} />
                  </Link>
                ) : (
                  <CardContent item={item} />
                )}
              </WrapperCard>
            </Slide>
          ))}
        </WrapperPrestations>
      </Container>
    </Section>
  )
}

export default Prestations
