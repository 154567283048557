import React, { useState } from "react"
import styled from "styled-components"

import Fleche from "../../../images/icons/fleche-violette.svg"

const Title = styled.h3`
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 18px;
  font-weight: bold;
  color: ${props => props.theme.secondary};
  background-color: ${props => props.theme.greyLighter};
  margin-bottom: 10px;
  padding: 10px;
  cursor: pointer;
`

const Content = styled.div`
  display: none;
  &.isActive {
    display: block;
  }
`

const Icon = styled.img`
  padding-right: 5px;
  transform: ${props => (props.isActive ? "rotate(180deg)" : null)};
  transition: transform 0.2s ease-in-out;
`

const Accordion = ({ title, children }) => {
  const [isActive, setIsActive] = useState(false)
  const handleClick = () => setIsActive(!isActive)

  return (
    <div>
      <Title onClick={handleClick}>
        {title} <Icon src={Fleche} isActive={isActive} />
      </Title>
      <Content className={isActive ? "isActive" : null}>{children}</Content>
    </div>
  )
}

export default Accordion
