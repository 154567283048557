import React, { useState, useEffect } from "react"
import styled from "styled-components"
import Flip from "react-reveal/Flip"

import Section from "../elements/Section"
import Container from "../elements/Container"
import { TitleH2 } from "../elements/Title"
import globalVariables from "../globalVariables"

const Text = styled.p`
  padding: 5px 0 20px;
  max-width: 720px;
  margin: auto;
  text-align: center;
  @media (min-width: ${globalVariables.minTablet}) {
    padding: 10px 0 25px;
  }
`

const WrapperSlider = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  margin-top: 35px;
  min-height: 100px;
  @media (min-width: ${globalVariables.minTablet}) {
    min-height: 140px;
  }
`

const Item = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  img {
    max-height: 100px;
    max-width: 120px;
    margin: 10px;
  }
  @media (min-width: ${globalVariables.minTablet}) {
    img {
      max-height: 125px;
      max-width: 180px;
      margin: 10px 20px;
    }
  }
`

const WrapperCategories = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  overflow-x: scroll;
  margin-top: 20px;
  @media (min-width: ${globalVariables.minTablet}) {
    justify-content: center;
    overflow: hidden;
  }
`

const Category = styled.div`
  flex-shrink: 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  border: ${globalVariables.borderWidth} solid
    ${props => (props.isActive ? props.theme.primary : props.theme.secondary)};
  color: ${props =>
    props.isActive ? props.theme.primary : props.theme.secondary};
  font-weight: bold;
  border-radius: ${globalVariables.borderRadiusButton};
  height: 55px;
  padding: 0 15px;
  margin: 0 10px 10px;
  cursor: pointer;
  transition: all 0.3s ease;
  @media (min-width: ${globalVariables.minTablet}) {
    min-width: 200px;
  }
`

const Outils = ({ title, text, items }) => {
  const [category, setCategory] = useState()
  const [categories, setCategories] = useState([])

  useEffect(() => {
    getCategories()
  }, [])

  const getCategories = () => {
    const categories = []
    items.forEach(item => {
      if (!categories.includes(item.categorie)) {
        categories.push(item.categorie)
      }
    })
    setCategories(categories)
    setCategory(categories[0])
  }

  return (
    <Section color="white" border>
      <Container>
        <TitleH2 className="title1" position="center">
          {title}
        </TitleH2>
        <Text>{text}</Text>
        <WrapperCategories>
          {categories.map((cat, index) => (
            <Category
              key={index}
              onClick={() => setCategory(cat)}
              isActive={cat === category}
            >
              {cat}
            </Category>
          ))}
        </WrapperCategories>
        <WrapperSlider>
          {items.map((item, index) =>
            item.categorie === category ? (
              <Flip key={index} left delay={index * 200}>
                <Item>
                  <img
                    src={item.logo.localFile.childImageSharp.fluid.src}
                    alt="outil"
                  />
                </Item>
              </Flip>
            ) : null
          )}
        </WrapperSlider>
      </Container>
    </Section>
  )
}

export default Outils
