import React from "react"
import styled from "styled-components"
import PropTypes from "prop-types"
import Parser from "html-react-parser"

import Accordion from "../Accordion/Accordion"
import Button from "../../elements/Button"
import Container from "../../elements/Container"
import Section from "../../elements/Section"
import Title, { TitleH2 } from "../Title"
import globalVariables from "../../globalVariables"

const Text = styled.p`
  padding: 5px 0 20px;
  max-width: 660px;
  margin: auto;
  text-align: center;
  @media (min-width: ${globalVariables.minTablet}) {
    padding: 8px 0 64px;
  }
`

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  .title4 {
    text-align: center;
  }
`

const Item = styled.div`
  flex: 1 0 100%;
  padding: 16px;
  @media (min-width: ${globalVariables.minTablet}) {
    flex: 1 0 50%;
  }
`

const Reponse = styled.span`
  padding: 10px;
  ul {
    padding-left: 15px;
  }
  img {
    height: 100%;
    max-width: 300px;
  }
`

const WrapperButton = styled.div`
  margin-bottom: 20px;
  text-align: right;
`

const Faq = ({ title, text, themes }) => {
  return (
    <Section color="greyLighter">
      <Container>
        <TitleH2 margin="0" className="title1" position="center">
          {title}
        </TitleH2>
        <Text>{text}</Text>
        <Wrapper>
          {themes.map((theme, index) => (
            <Item key={index}>
              <Title className="title4">{theme.titre_theme_faq}</Title>
              {theme.questions_faq.map((item, i) => (
                <Accordion key={i} title={item.question_faq}>
                  <div>
                    <Reponse>{Parser(item.reponse_faq)}</Reponse>
                    {item.lien_faq && item.texte_bouton_faq && (
                      <WrapperButton>
                        <Button
                          to={item.lien_faq}
                          title={item.texte_bouton_faq}
                          colors="primaryWithBg"
                          as="external-link"
                        />
                      </WrapperButton>
                    )}
                  </div>
                </Accordion>
              ))}
            </Item>
          ))}
        </Wrapper>
      </Container>
    </Section>
  )
}

Faq.propTypes = {
  title: PropTypes.string,
  text: PropTypes.string,
}

export default Faq
